<template>
	<div class="p-modal-scroll">
		<div class="p-modal-agreement">
			<template v-if="isSelected">
				<PSelect
					type="select"
					v-model="selected"
					:options="termDropdownOptions"
					option-label="name"
					option-value="value"
					@change="(e) => onChange(termData?.termType, e.value)"
				/>
			</template>
			<!-- <iframe :src="termsUrl" style="width: 100%; height: 80vh; word-break: break-all; overflow-y: visible"></iframe> -->
			<div v-html="termData?.termDescription"></div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { inject } from 'vue'
import AuthService from '~~/home/services/auth/AuthService'
import type { TermsDetailResponseType } from '~~/home/types/auth/auth'
const authService = new AuthService()

const dialogRef = inject('dialogRef')

const selected = ref()
const termDropdownOptions = ref()
const termData = ref<TermsDetailResponseType | undefined>(dialogRef.value?.data?.termData)
const isSelected = computed(() => dialogRef.value?.data?.termData?.termType === 2 || dialogRef.value?.data?.termData?.termType === 100)

const getTermDetail = async (termType: number, termId?: string) => {
	if (termId) {
		return await authService.getTermsDetail({ termType, termId })
	} else {
		return await authService.getTermsDetail({ termType })
	}
}

const onChange = async (termType: number, termId: string) => {
	const { data } = await getTermDetail(termType, termId)
	termData.value = data
}

if (dialogRef.value.data?.termType) {
	const { data } = await getTermDetail(dialogRef.value.data?.termType, termData.value?.termId)
	termData.value = data
}

if (isSelected.value) {
	const { data: termDropdown } = await useAsyncData(`term-${termData.value?.termType}-dropdown`, () =>
		authService.getTermsDropdown({ termType: termData.value?.termType })
	)
	selected.value = termDropdown.value?.data?.list?.[0].agreeItemId
	termDropdownOptions.value = termDropdown.value?.data?.list.map((terms) => {
		return {
			name: terms.createdAt,
			value: terms.agreeItemId
		}
	})
}
</script>

<style lang="scss" scoped>
.p-modal-agreement {
	:deep(.input-set-wrap) {
		width: 200px;
	}

	p {
		font-size: 13px;
		& strong {
			font-size: 15px;
		}
	}

	:deep(table) {
		width: 100%;
		table-layout: fixed;
		border-collapse: collapse;
		th {
			font-size: 13px;
			font-weight: 400;
			border-width: 1pt;
			border-style: solid;
			border-color: currentcolor windowtext windowtext;
			padding: 0cm 5.4pt;
			vertical-align: top;
		}
		td {
			font-size: 13px;
			border-width: medium 1pt 1pt;
			border-style: none solid solid;
			border-color: currentcolor windowtext windowtext;
			padding: 0cm 5.4pt;
			vertical-align: top;
			word-wrap: break-word;
		}
	}
}
</style>
